<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="">
            <el-dialog
              title="Add Course to Answer"
              :visible="isVisible"
              :before-close="closeDialog"
              width="80%"
              @open="onOpen"
            >
              <div v-loading="loading" class="">
                <div
                  style="
                    margin: 0px 0px 30px 0px;
                    padding: 10px;
                    background-color: #f6f6f6;
                  "
                  class=""
                >
                  <div
                    class=""
                    v-for="faculty in faculties"
                    :key="faculty.faculty_id"
                  >
                    <div class="mt-4">
                      <span
                        style="
                          font-size: 18px;
                          font-weight: 400;
                          color: #164b70;
                        "
                      >
                        <strong>{{ faculty.title }} </strong>
                      </span>
                    </div>
                    <el-checkbox-group
                      class="mt-4"
                      v-model="selectedCourseUnitIds"
                    >
                      <el-checkbox
                        class="mx-0 col-lg-6"
                        v-for="course in faculty.courses"
                        :label="course.course_id"
                        :key="course.course_id"
                      >
                        {{ course.title }} (<strong>{{
                          course.course_code
                        }}</strong
                        >)
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <el-button
                    @click="addCoursesToAnswer()"
                    style="
                      background-color: var(--el-app-primary);
                      color: white;
                    "
                    >Save Changes <i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    answer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingError: false,
      faculties: [],
      courses: [],
      selectedCourseUnitIds: [],
    };
  },

  methods: {
    onOpen() {
      this.selectedCourseUnitIds = this.answer.courses.map((course) => {
        return course.course_id;
      });
      this.fetchAllFacultiesWithProgrammes();
    },

    async fetchAllFacultiesWithProgrammes() {
      try {
        this.loading = true;
        let response = await this.$http.get("faculties/with-programmes");

        if (
          response.data.success &&
          response.data.message ==
            "FACULTIES WITH PROGRAMMES FETCHED SUCCESSFULLY"
        ) {
          this.faculties = response.data.faculties;
        } else {
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Fetch Faculties",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async addCoursesToAnswer() {
      try {
        this.loading = true;
        let response = await this.$http.patch(
          `website/online-quiz/answers/add-courses/${this.answer.website_online_quiz_answer_id}`,
          {
            course_ids: this.selectedCourseUnitIds,
          }
        );
        if (
          response.data.success &&
          response.data.message == "COURSES SAVED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Success",
            "Answer Courses Added Successfully"
          );
          this.closeDialog();
          this.$emit("re-fresh");
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Add Courses to Answer",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
