<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Online Quiz']" />
            </div>
          </div>
          <div v-loading="isBusy">
            <div v-if="question == null">
              <div class="row w-100">
                <div class="col-12 text-left">
                  <div
                    style="
                      font-size: 20px;
                      font-weight: 400;
                      color: var(--el-app-primary);
                      margin-bottom: 15px;
                    "
                  >
                    <i
                      @click="$router.go(-1)"
                      class="fas fa-chevron-circle-left bread-crumb-home mr-2"
                      style="font-size: 23px; color: #919191; cursor: pointer"
                    ></i>
                    <strong>Online Quiz </strong><br />
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <el-empty description="No Question"></el-empty>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <AddQuestion
                    :rootAnswerId="websiteOnlineAnswerId"
                    @re-fresh="fetchOneQuestion"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row w-100">
                <div class="col-12 text-left">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        font-size: 20px;
                        font-weight: 400;
                        color: var(--el-app-primary);
                        margin-bottom: 15px;
                      "
                    >
                      <i
                        @click="$router.go(-1)"
                        class="fas fa-chevron-circle-left bread-crumb-home mr-2"
                        style="font-size: 23px; color: #919191; cursor: pointer"
                      ></i>
                      <strong class="">{{ question.title || "..." }} </strong>
                    </div>
                    <div>
                      <span>
                        <el-tooltip content="Edit Question" placement="top">
                          <el-button
                            type="primary"
                            @click="openEditQuestionDialog(question)"
                            icon="el-icon-edit"
                            circle
                            size="small"
                          >
                          </el-button>
                        </el-tooltip>
                      </span>
                      <span>
                        <el-tooltip content="Delete Question" placement="top">
                          <el-button
                            type="danger"
                            @click="
                              deleteQuestion(
                                question.website_online_quiz_question_id
                              )
                            "
                            icon="el-icon-delete"
                            circle
                            size="small"
                            class="ml-2"
                          ></el-button>
                        </el-tooltip>
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="col-12">
                  <div class="" style="float: right">
                    <AddAnswer
                      :isRootAnswer="isRootAnswer"
                      :answerFormat="answerFormat"
                      :questionId="websiteOnlineQuizQuestionId"
                      @re-fresh="fetchOneQuestion"
                    />
                  </div>
                </div>
              </div>
              <!-- <hr /> -->
              <div v-loading="loading">
                <div v-if="answers.length === 0">
                  <div>
                    <el-empty description="No Answers"></el-empty>
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    ></div>
                  </div>
                </div>

                <div v-else-if="answerFormat === 'TITLE_WITH_IMAGE'">
                  <div class="row w-100">
                    <div
                      class="col-md-3 mt-2"
                      v-for="(answer, index) in answers"
                      :key="index"
                    >
                      <div
                        class="img-answer-card pb-2"
                        @click="
                          goToAnswer(answer.website_online_quiz_answer_id)
                        "
                      >
                        <div class="edit-del-btn">
                          <el-button
                            type="success"
                            @click.stop="openAddCourseToAnswerDialog(answer)"
                            icon="el-icon-more"
                            circle
                            size="small"
                          >
                          </el-button>
                          <el-button
                            type="warning"
                            icon="el-icon-edit"
                            circle
                            size="small"
                            @click.stop="openEditAnswerDialog(answer)"
                          ></el-button>
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            size="small"
                            @click.stop="
                              deleteAnswer(answer.website_online_quiz_answer_id)
                            "
                          ></el-button>
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-top"
                          style="width: 100%; height: 250px"
                        >
                          <img
                            :src="answer.image_path_url"
                            class="img-fluid"
                            style="width: 100%; object-fit: cover"
                            alt=""
                          />
                        </div>

                        <div class="pl-0 pr-3 mt-2 pb-2 col-12">
                          <div
                            style="
                              color: #000;
                              padding: 0px 10px;
                              color: rgba(0, 0, 0, 0.6);
                              font-weight: 500;
                              text-transform: capitalize;
                            "
                            class="pb-0 text-left d-block text-truncate"
                          >
                            {{ answer.title }}
                          </div>
                        </div>
                        <div
                          class="tag-items ml-2"
                          v-if="answer.courses.length > 0"
                        >
                          <span
                            v-for="course in answer.courses"
                            :key="course.course_id"
                          >
                            <el-tooltip
                              effect="dark"
                              placement="top-start"
                              :content="course.title"
                              :open-delay="300"
                            >
                              <p class="tag">
                                {{ course.course_code }}
                              </p>
                            </el-tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else-if="answerFormat === 'TITLE_ONLY'">
                  <div
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="row w-100 mt-2 px-3"
                  >
                    <div
                      class="col-12 p-3 d-flex justify-content-between align-items-center answer-card"
                      @click="goToAnswer(answer.website_online_quiz_answer_id)"
                    >
                      <div class="mt-2">
                        <p>{{ answer.title }}</p>
                        <div
                          class="tag-items ml-0"
                          v-if="answer.courses.length > 0"
                        >
                          <span
                            v-for="course in answer.courses"
                            :key="course.course_id"
                          >
                            <el-tooltip
                              effect="dark"
                              placement="top-start"
                              :content="course.title"
                              :open-delay="300"
                            >
                              <p class="tag">
                                {{ course.course_code }}
                              </p>
                            </el-tooltip>
                          </span>
                        </div>
                      </div>
                      <div>
                        <span>
                          <el-tooltip content="Add Course" placement="top">
                            <el-button
                              type="success"
                              @click.stop="openAddCourseToAnswerDialog(answer)"
                              icon="el-icon-more"
                              circle
                              size="small"
                              class="mr-2"
                            >
                            </el-button>
                          </el-tooltip>
                        </span>
                        <span>
                          <el-tooltip content="Edit" placement="top">
                            <el-button
                              type="primary"
                              @click.stop="openEditAnswerDialog(answer)"
                              icon="el-icon-edit"
                              circle
                              size="small"
                            >
                            </el-button>
                          </el-tooltip>
                        </span>
                        <span>
                          <el-tooltip content="Delete" placement="top">
                            <el-button
                              type="danger"
                              @click.stop="
                                deleteAnswer(
                                  answer.website_online_quiz_answer_id
                                )
                              "
                              icon="el-icon-delete"
                              circle
                              size="small"
                              class="ml-2"
                            ></el-button>
                          </el-tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <EditQuestionDialog
        :isVisible="editQuestionDialog"
        :question="question"
        @re-fresh="fetchOneQuestion"
        @close-dialog="closeEditQuestionDialog"
      />

      <EditAnswerDialog
        :isVisible="editAnswerDialog"
        :answer="answer"
        @re-fresh="fetchOneQuestion"
        :answerFormat="answerFormat"
        @close-dialog="closeEditAnswerDialog"
      />

      <AddCourseToAnswer
        :isVisible="addCourseToAnswerDialog"
        :answer="answer"
        @re-fresh="fetchOneQuestion"
        @close-dialog="closeAddCourseToAnswerDialog"
      />
    </article>
  </div>
</template>

<script>
// import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddQuestion from "../components/dialogs/add-question-dialog.vue";
import AddAnswer from "../components/dialogs/add-answer-dialog.vue";
import EditQuestionDialog from "../components/dialogs/edit-question-dialog.vue";
import EditAnswerDialog from "../components/dialogs/edit-answer-dialog.vue";
import AddCourseToAnswer from "../components/dialogs/add-course-to-answer-dialog.vue";

export default {
  components: {
    // ScaleOut,
    BreadCrumbComponent,
    AddQuestion,
    AddAnswer,
    EditQuestionDialog,
    EditAnswerDialog,
    AddCourseToAnswer,
  },

  data() {
    return {
      loading: false,
      isBusy: false,
      loadingError: false,
      isRootAnswer: false,
      rooAnswerId: null,
      websiteOnlineAnswerId: this.$route.params.answer_id,
      websiteOnlineQuizQuestionId: "",
      question: {},
      editQuestionDialog: false,
      editAnswerDialog: false,
      addCourseToAnswerDialog: false,
      answers: [],
      answer: {},
      answerFormat: "",
    };
  },

  watch: {
    "$route.params.answer_id": {
      handler() {
        this.websiteOnlineAnswerId = this.$route.params.answer_id;
        this.fetchOneQuestion();
      },
    },
  },

  mounted() {
    this.fetchOneQuestion();
  },

  methods: {
    async fetchOneQuestion() {
      try {
        this.loading = true;
        let response;
        if (this.websiteOnlineAnswerId === "root") {
          response = await this.$http.get(
            `website/online-quiz/questions/fetch-one?is_root_question=true`
          );
        } else {
          response = await this.$http.get(
            `website/online-quiz/questions/fetch-one?root_answer_id=${this.websiteOnlineAnswerId}`
          );
        }

        if (
          response.data.success &&
          response.data.message == "QUESTION FETCHED SUCCESSFULLY"
        ) {
          this.question = response.data.question;
          this.answers = response.data.question?.answers;
          this.answerFormat =
            response.data.question?.answer_format ?? "TITLE_ONLY";
          this.websiteOnlineQuizQuestionId =
            response.data.question?.website_online_quiz_question_id;
        } else {
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Fetch Quiz Question",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async deleteQuestion(questionId) {
      await this.$confirm(
        "This will permanently delete the Question. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.loading = true;
        let request = await this.$http.delete(
          `website/online-quiz/questions/${questionId}`
        );
        if (
          request.data.success &&
          request.data.message == "QUESTION DELETED SUCCESSFULLY"
        ) {
          this.fetchOneQuestion();
          this.showSuccessMessage("Success", "Question deleted successfully");
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to delete Question",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async deleteAnswer(answerId) {
      await this.$confirm(
        "This will permanently delete the Answer. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.loading = true;
        let request = await this.$http.delete(
          `website/online-quiz/answers/${answerId}`
        );
        if (
          request.data.success &&
          request.data.message == "ANSWER DELETED SUCCESSFULLY"
        ) {
          this.fetchOneQuestion();
          this.showSuccessMessage(
            "Answer Deleted",
            "The answer was successfully deleted."
          );
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to delete Answer",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    goToAnswer(answerId) {
      this.$router.push({
        name: "OnlineQuiz",
        params: {
          answer_id: answerId,
          root_answer_id: answerId,
        },
        force: true,
      });
    },

    openEditQuestionDialog(question) {
      this.question = question;
      this.editQuestionDialog = true;
    },

    openEditAnswerDialog(answer) {
      this.answer = answer;
      this.editAnswerDialog = true;
    },

    openAddCourseToAnswerDialog(answer) {
      this.answer = answer;
      this.addCourseToAnswerDialog = true;
    },

    closeEditQuestionDialog() {
      this.editQuestionDialog = false;
    },

    closeEditAnswerDialog() {
      this.editAnswerDialog = false;
    },

    closeAddCourseToAnswerDialog() {
      this.addCourseToAnswerDialog = false;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.edit-del-btn {
  display: none;
  z-index: 100;
}

.answer-card {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.img-answer-card {
  background-color: #fff;
  cursor: pointer;
}

.img-answer-card:hover .edit-del-btn {
  display: block;
  position: absolute;
  top: 0px;
  right: 15px;
  padding: 5px;
}

.edit-del-btn .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}
.tag {
  display: inline-block;
  border-radius: 12px;
  padding: 0.2em 0.5em 0.3em;
  color: rgb(145, 141, 141);
  border: rgb(145, 141, 141) 1px solid;
  font-weight: 600;
  font-size: 0.8em;
  margin: 0 0.1rem;
  margin-top: 0.4rem;
  transition: color 0.5s ease-in-out;
}
.tag-items {
  margin-top: -0.5rem;
}
</style>
