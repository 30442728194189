<template>
  <div>
    <article>
      <el-button style="
          background-color: var(--el-app-primary);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        class="z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="showModal">
        Add Question
      </el-button>

      <section>
        <div class="container">
          <div class="batch_dialogs">
            <el-dialog title="Add Question"
              :visible.sync="addQuestionModal"
              :destroy-on-close="true"
              :before-close="handleClose"
              width="35%">
              <div v-loading="loading"
                class="">
                <div class="row">
                  <div class="col-md-12">
                    <label>Title</label>
                    <el-input v-model="title"
                      placeholder="Title"></el-input>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label>Answer Type</label>
                    <el-radio-group v-model="answerFormat">
                      <el-radio label="TITLE_ONLY">Title Only</el-radio>
                      <el-radio label="TITLE_WITH_IMAGE">Title with image</el-radio>
                    </el-radio-group>
                  </div>
                </div>

                <div class="form-group text-center">
                  <el-button style="
                      background-color: var(--el-app-primary);
                      color: white;
                    "
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    class="z-depth-0 mt-4"
                    @click="addQuestion">
                    Add Question
                  </el-button>
                </div>

                <hr />

                <div class="text-center">
                  <div class="text-center">
                    <small></small>
                  </div>
                  <div class="text-center"></div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    rootAnswerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      addQuestionModal: false,
      loading: false,
      loadingError: false,
      title: "",
      answerFormat: "",
    };
  },

  computed: {
    isRootQuestion() {
      return this.rootAnswerId === "root"
    }
  },

  methods: {
    // _____ close modal
    handleClose(value) {
      value();
    },

    showModal() {
      this.addQuestionModal = true;
    },

    async addQuestion() {
      if (this.title.length > 255) {
        return this.showWarningMessage(
          "Title too long",
          "The title should not be more than 255 characters"
        );
      }
      try {
        this.loading = true;
        let response = await this.$http.post("website/online-quiz/questions", {
          title: this.title,
          answer_format: this.answerFormat,
          root_answer_id: this.rootAnswerId,
          is_root_question: this.isRootQuestion,
        });

        if (
          response.data.success &&
          response.data.message == "QUESTION CREATED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Question Added",
            "The Question was successfully added."
          );
          this.addQuestionModal = false;
          this.$emit("re-fresh");
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to add Question",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.grading-errors {
  color: red;
  font-size: 0.7em;
}

.grading-errors ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
