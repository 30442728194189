<template>
  <div>
    <article>
      <el-button
        style="
          background-color: var(--el-app-primary);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        class="z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="showModal"
      >
        Add Answer
      </el-button>

      <section>
        <div class="container">
          <div class="batch_dialogs">
            <el-dialog
              title="Add Answer"
              :visible.sync="addAnswerModal"
              :destroy-on-close="true"
              :before-close="handleClose"
              width="35%"
            >
              <div v-loading="loading" class="">
                <div class="row">
                  <div class="col-md-12">
                    <label>Title</label>
                    <el-input v-model="title" placeholder="Title"></el-input>
                  </div>
                  <div
                    class="col-md-12 mt-3"
                    v-if="answerFormat === 'TITLE_WITH_IMAGE'"
                  >
                    <div
                      class="mb-2 mt-3"
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
                    >
                      Answer Image
                    </div>
                    <div class="w-100 text-left">
                      <div
                        v-if="image"
                        class="position-relative answer-photo"
                        style="background: #fdfdfd"
                      >
                        <img
                          :src="image"
                          style="width: 100%; height: 100%; object-fit: cover"
                        />
                        <div
                          class="remove-img w-100 d-flex align-items-center justify-content-center"
                          style="height: 100%"
                        >
                          <el-button
                            class="remove-img-btn"
                            icon="el-icon-delete"
                            style="
                              font-size: 1.5rem;
                              color: #fff;
                              background-color: rgba(0, 0, 0, 0.45);
                            "
                            @click="handleRemove"
                          >
                          </el-button>
                        </div>
                      </div>

                      <div v-else>
                        <input
                          id="answer-photo"
                          type="file"
                          class="d-none"
                          accept=".jpg,.png,.jpeg"
                          @change="onSelectAnswerPhotoChange"
                          @click="onAnswerPhotoInputClick"
                        />
                        <label for="answer-photo" class="add-img">
                          <div
                            class="w-100 d-flex align-items-center justify-content-center"
                            style="height: 200px"
                          >
                            <span class="el-icon-plus"></span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group text-center">
                  <el-button
                    style="
                      background-color: var(--el-app-primary);
                      color: white;
                    "
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    class="z-depth-0 mt-4"
                    @click="addAnswer"
                  >
                    Add Answer
                  </el-button>
                </div>

                <hr />

                <div class="text-center">
                  <div class="text-center">
                    <small></small>
                  </div>
                  <div class="text-center"></div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    questionId: {
      type: String,
      required: true,
    },
    isRootAnswer: {
      type: Boolean,
      required: true,
    },
    answerFormat: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addAnswerModal: false,
      loading: false,
      loadingError: false,
      title: "",
      rooAnswerId: null,
      selectedFile: null,
      image: "",
    };
  },

  methods: {
    // Do this to make sure change event for the input fires even after choosing same file
    onAnswerPhotoInputClick(e) {
      e.target.value = "";
    },
    onSelectAnswerPhotoChange(e) {
      e.preventDefault();
      const ALLOWED_FILE_EXTENSIONS = ["png", "jpg", "jpeg"];

      // Ensure the file type chosen is an image in the allowed file extensions
      this.selectedFile = e.target.files[0];
      const fileExtension = this.selectedFile.name
        ?.split(".")
        ?.pop()
        ?.toLowerCase();
      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        return this.showWarningMessage(
          "Invalid file-Type",
          `Only images of type ${ALLOWED_FILE_EXTENSIONS.toString()} are allowed`
        );
      }

      // Otherwise set the image url to show on the UI
      this.image = URL.createObjectURL(this.selectedFile);
    },
    handleRemove() {
      this.image = "";
      this.selectedFile = null;
    },

    clearInputs() {
      this.title = "";
      this.selectedFile = null;
      this.image = "";
    },
    // _____ close modal
    handleClose(value) {
      value();
      this.clearInputs();
    },

    showModal() {
      this.addAnswerModal = true;
    },

    async addAnswer() {
      if (this.title.length > 255) {
        return this.showWarningMessage(
          "Title too long",
          "The title should not be more than 255 characters"
        );
      }
      try {
        this.loading = true;
        if (this.answerFormat === "TITLE_WITH_IMAGE") {
          const awsUploadUrl = await this.requestUploadUrl();
          const answerImagePathUrl = await this.uploadAnswerPhotoToAWS(
            awsUploadUrl,
            this.selectedFile
          );
          let response = await this.$http.post("website/online-quiz/answers", {
            title: this.title,
            question_id: this.questionId,
            image_path_url: answerImagePathUrl,
          });

          if (
            response.data.success &&
            response.data.message == "ANSWER CREATED SUCCESSFULLY"
          ) {
            this.showSuccessMessage(
              "Answer Added",
              "The Answer was successfully added."
            );
            this.addAnswerModal = false;
            this.clearInputs();
            this.$emit("re-fresh");
          } else throw "UNEXPECTED_RESPONSE";
        } else {
          let response = await this.$http.post("website/online-quiz/answers", {
            title: this.title,
            question_id: this.questionId,
          });

          if (
            response.data.success &&
            response.data.message == "ANSWER CREATED SUCCESSFULLY"
          ) {
            this.showSuccessMessage(
              "Answer Added",
              "The Answer was successfully added."
            );
            this.addAnswerModal = false;
            this.clearInputs();
            this.$emit("re-fresh");
          } else throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
          return this.$emit("re-fresh");
        }
        if (error.response) {
          if (error.response.data.message === "QUESTION NOT FOUND") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the question for the provided answer"
            );
          }
        }
        this.showFailedMessage(
          "Unable to add Answer",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async requestUploadUrl() {
      try {
        this.loading = true;
        let request = await this.$http.post(
          "website/online-quiz/answers/request-image-upload-url",
          {
            file_name: this.selectedFile.name,
            file_size: this.selectedFile.size,
          }
        );
        if (
          request.data.success &&
          request.data.message == "S3 UPLOAD URL GENERATED"
        ) {
          return request.data.aws_upload_url;
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.isUploadingFile = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        if (error.response) {
          if (
            error.response.data.message ===
            "INVALID FILE TYPE. ONLY [jpg, jpeg, png] ARE ALLOWED"
          ) {
            return this.showWarningMessage(
              "Invalid File Type",
              "Only images of type jpg, jpeg, png are allowed."
            );
          } else if (
            error.response.data.message === "FILE SIZE MUST BE LESS THAN 1MB"
          ) {
            return this.showWarningMessage(
              "Image too Big",
              "The image should not be greater than 1Mbs"
            );
          }
        }
        this.loading = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload the Answer Photo now, Please try again"
        );
      }
    },

    async uploadAnswerPhotoToAWS(aws_upload_url, selected_file) {
      this.loading = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.loading = true;

        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
        });

        if (request.status == 204) {
          return request.headers.location;
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loading = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loading = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Photo now, Please try again"
        );
      }
    },
  },
};
</script>

<style scoped>
.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  height: 80%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-img {
  display: none;
}
.remove-img-btn {
  display: none;
}

.answer-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.answer-photo:hover .remove-img-btn {
  display: block;
}
</style>
