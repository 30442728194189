<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="">
            <el-dialog
              title="Edit Question"
              :visible="isVisible"
              :before-close="closeDialog"
              width="35%"
              @open="onOpen"
            >
              <div v-loading="loading" class="">
                <el-form class="demo-batchForm2">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Title</label>
                      <el-input v-model="title" placeholder="Title"></el-input>
                    </div>
                  </div>

                  <el-form-item class="text-center mt-3">
                    <el-button
                      style="
                        background-color: var(--el-app-primary);
                        color: white;
                      "
                      @click="editQuestion"
                    >
                      Edit Question
                    </el-button>
                  </el-form-item>
                  <hr />

                  <div class="text-center">
                    <div class="text-center">
                      <small></small>
                    </div>
                    <div class="text-center"></div>
                  </div>
                </el-form>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loadingError: false,
      title: "",
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    question: {
      type: Object,
      required: false,
    },
  },

  methods: {
    onOpen() {
      this.title = this.question.title;
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    async editQuestion() {
      try {
        this.loading = true;
        let response = await this.$http.patch(
          `website/online-quiz/questions/${this.question.website_online_quiz_question_id}`,
          {
            title: this.title,
          }
        );
        if (
          response.data.success &&
          response.data.message == "QUESTION EDITED SUCCESSFULLY"
        ) {
          this.showSuccessMessage("Success", "Question edited successfully");
          this.closeDialog();
          this.$emit("re-fresh");
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to edit Question",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
